import ElementModel from "./ElementModel";
import Model from "@lernetz/ts-lib/src/core/model/Model";


export class TitleModel extends Model implements ElementModel {

    title:string;

    public get viewName(): string {
        return 'Title';
    }

    public matchesSearchText(searchText: string): boolean {
        return this.title.toLowerCase().indexOf(searchText) >= 0;
    }
}

