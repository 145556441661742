import ElementModel from '../elements/ElementModel';
import Model from '@lernetz/ts-lib/src/core/model/Model';

export class TermModel extends Model {

    isOpen:boolean;
    elements:ElementModel[];
    title:string;
    slug:string;


    public matchesSearchText(searchText: string) {
        return this.title.toLowerCase().indexOf(searchText) >= 0
            || this.elements.some(element => element.matchesSearchText(searchText));
    }
}