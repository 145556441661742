import Repeat from '@lernetz/ts-lib/src/components/Repeat';
import Image from '@lernetz/ts-lib/src/components/Image';
import Show from '@lernetz/ts-lib/src/components/Show';
import Html from '@lernetz/ts-lib/src/components/Html';
import Trans from '@lernetz/ts-lib/src/components/Trans';
import { mapper } from '@lernetz/ts-lib/src/core/model/Mapper';
import { register } from '@lernetz/ts-lib/src/core/view/View';
import App from './components/app/App';
import { SearchInput } from './components/app/SearchInput';
import { Image as ElementImage, ImageModel } from './components/elements/Image';
import { ParagraphModel } from './components/elements/Paragraph';
import { TitleModel } from './components/elements/Title';
import { TermModel } from './components/glossary/Term';
import { SvgIcon } from './components/svg/SvgIcon';
import { Paragraph, Title } from './templates/components/elements';
import { Term } from './templates/components/glossary';

register( 'StaticImage', Image );
register( 'Repeat', Repeat );
register( 'Show', Show );
register( 'Html', Html );
register( 'Trans', Trans );

register( 'App', App );


register( 'SvgIcon', SvgIcon );


register( 'Term', { template:Term } );
mapper.toModel.add('App\\Term', dto => new TermModel( { ...dto, isOpen:false } ) );


register( 'Image', ElementImage );
mapper.toModel.add('App\\Image', dto => new ImageModel( dto ) );


register( 'Paragraph', { template:Paragraph } );
mapper.toModel.add('App\\Paragraph', dto => new ParagraphModel( dto ) );


register( 'Title', { template:Title } );
mapper.toModel.add('App\\Title', dto => new TitleModel( dto ) );

register( 'SearchInput', SearchInput );

