import Model from '@lernetz/ts-lib/src/core/model/Model';
import View from '@lernetz/ts-lib/src/core/view/View';
import { App as template } from '../../templates/components/app';
import { TermModel } from '../glossary/Term';
import { SearchInputModel } from './SearchInput';
import PropertyTween from '@lernetz/ts-lib/src/core/animation/PropertyTween';
import { inOutCubic } from '@lernetz/ts-lib/src/core/animation/Easing';

export default class App extends View<AppModel> {

    public constructor() {
        super({ data: new AppModel( { 
            isMenuOpen:false, 
            searchInput:new SearchInputModel( { isOpen:false, text:'' } ),
            title:'',
            locales:[],
            active:null,
            filteredTerms:[]
        } ), template });
    }

    protected init() {
        window.addEventListener('scroll', this.closeMenu.bind(this));

        this.data.change.filter( c => c.name == 'active' ).subscribe( this.onActiveChange.bind( this ) );
        this.data.change.filter( c => c.name == 'filteredTerms' ).subscribe( this.onFilter.bind( this ) );

        this.openHashTerm();
    }


    /**
     * Callback when the active term has changed
     */
    private onActiveChange( event:{ name:string, newValue:TermModel } ) {
        window.location.hash = `#${event.newValue.slug}`;
        this.scrollToTerm(event.newValue);
    }

    /**
     * Callback when the terms are filtered
     */
    private onFilter() {
        window.scrollTo( { top:0 } );
    }

    private openHashTerm() {
        let hash = window.location.hash.slice( 1 );
        let found = this.data.terms.filter( term => term.slug == hash )[0];
        if( found ) this.data.onTermOpen( found );
    }

    private closeMenu() {
        this.data.isMenuOpen = false;
    }

    private scrollToTerm( term: TermModel ) {
        const node = this.node.querySelector(`.slug-${term.slug}`);
        if (node) {
            let proxy = new ScrollProxy();
            let tween = new PropertyTween( proxy );
            const top = proxy.top + node.getBoundingClientRect().top - 70;
            tween.to( { top:top }, 0.2, inOutCubic );
        }
    }
}


export class AppModel extends Model {

    isMenuOpen:boolean;
    searchInput:{ isOpen:boolean, text:'' };
    title:string;
    active:TermModel;
    filteredTerms:TermModel[];

    /**
     * Callback when a term is clicked
     * @param term 
     */
    onTermOpen( term:TermModel ) {

        // close old term
        if( this.active ) {
            this.active.isOpen = false;
        }

        // activate/open new term
        term.isOpen = true;
        this.active = term;
    }

    onLetterSelect( term:TermModel ) {

        // make sure no search is active
        this.searchInput.text = '';
        this.searchInput.isOpen = false;

        this.onTermOpen( term );
    }

    /**
     * Returns for each letter in alphabet the first term
     */
    get alphabeticalTerms():{ letter:string, term:TermModel }[] {
        let letter = '';
        
        return this.terms.reduce( ( acc, cur ) => {

            // check if letter already exists
            let current = cur.title.charAt( 0 ).toLowerCase();
            if( letter != current ) {
                letter = current;
                acc.push( { letter:current, term: cur } );
            }
            return acc;
        }, [] );
    }


    onFilter() {
        let text = this.searchInput.text.toLowerCase();
        this.filteredTerms = ( text == '' ) ? this.terms : this.terms.filter( term => term.matchesSearchText( text ) );
    }

    set terms( t:TermModel[] ) {
        t = t.sort( ( a, b ) => {
            return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
        });
        this.set( 'terms', t );
        this.onFilter();
    }
    get terms():TermModel[] {
        return this.get( 'terms' );
    }
}


class ScrollProxy {
    set top( v:number ) {
        window.scrollTo( this.left, v );
    }
    
    get top():number {
        return (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body)[ 'scrollTop' ];
    }
    
    set left( v:number ) {
        window.scrollTo( v, this.top );
    }
    get left() {
        return (window.pageXOffset !== undefined) ? window.pageXOffset : (document.documentElement || document.body.parentNode || document.body)[ 'scrollLeft' ];
    }
}
