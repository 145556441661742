import { svg } from '../../templates/components/svg';
import View from '@lernetz/ts-lib/src/core/view/View';
import Model from '@lernetz/ts-lib/src/core/model/Model';

export class SvgIcon extends View<SvgIconModel> {
    constructor() {
        super( { data: new SvgIconModel( { name:'', size:'-medium' } ), template: svg } );
    }
}

export class SvgIconModel extends Model {
    name:string;
    size:string;
}