import ElementModel from "./ElementModel";
import Model from "@lernetz/ts-lib/src/core/model/Model";


export class ParagraphModel extends Model implements ElementModel {

    text:string;

    public constructor(dto) {
        super(dto);
    }

    public get viewName(): string {
        return 'Paragraph';
    }

    public matchesSearchText(searchText: string): boolean {
        return this.text.toLowerCase().indexOf(searchText) >= 0;
    }
}

